import React from 'react'
import './Plans.css'
import { plansData } from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'

const Plans = () => {
    return (
        <div className="plans-container" id="plans">
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>
            <div className="programs-header" style={{ gap: '2rem' }}>
                <span className='stroke-text'>START</span>
                <span>YOUR TRANSFORMATION</span>
                <span className='stroke-text'>NOW</span>
            </div>

            {/* change plansdata to say that each upgrade includes cheaper plans benefits */}



            {/* Make backup of Plans folder then try taking card html and making a 'PlansCard.jsx with Plans.css css imported or new PlansCard css and take all required css and plansData imported and then import PlansCard here and do <PlansCard /> */}

            {/* plans card */}
            <div className="plans">
                {/* i added plan._id its meant to be i */}
                {plansData.map((plan) => (
                    <div className="plan" key={plan._id}>
                        {plan.icon}
                        <span className='stroke-text'>{plan.recommended}</span>
                        <span>{plan.name}</span>
                        <span>$ {plan.price}</span>

                        <div className="features">
                            {plan.features.map((feature, i) => (
                                <div className="feature" key={i}>
                                    <img src={whiteTick} alt="" />
                                    <span>{feature}</span>
                                </div>
                            ))}
                        </div>

                        <div>
                            <span>See more benefits -{'>'}</span>
                        </div>
                        <button className="btn">Join now</button>

                    </div>
                ))}
            </div>
        </div >
    )
}

export default Plans