import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";
import image4 from "../assets/t-image4.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "High quality training program and great coaches!",
    name: 'MATHEW HENDRICKSON',
    status: 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'After following FitClubs training plan for years i decided to join the community as a coach.',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image: image3,
    review: 'FitClub has many fitness programs of different types to suit your goals!',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  },
  {
    image: image4,
    review:
      "I bench more than all the coaches combined.",
    name: 'Daniel Jenkins',
    status: 'BODYBUILDING LEGEND'
  },
];
